import * as React from 'react'

import {Layout} from 'layouts'

const Page = () => (
  <Layout
    title="404: Not found"
    description=""
    className="h-full bg-[url(assets/images/bg-pricing.svg)] bg-contain bg-top bg-no-repeat relative"
  >
    <div className="flex flex-col items-center justify-center h-96">
      <h1 className="text-9xl font-bold text-center uppercase text-gray-900">
        404
      </h1>
      <p className="text-3xl text-center text-gray-700 my-5">
        We can’t seem to find the page you’re looking for.
      </p>
      <div className="flex items-center text-base font-semibold text-center uppercase text-gray-900 my-5">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mr-2"
          preserveAspectRatio="none"
        >
          <path
            d="M10 19L3 12M3 12L10 5M3 12L21 12"
            stroke="#111827"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        go home
      </div>
    </div>
  </Layout>
)

export default Page
